import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className = "home-about-body" >
              I fell in love with programming and I have at least learnt
              something, I believe… 🤷‍♂️
              <br />
              <br />I am familiar with classics like
              <i >
                <b className = "purple" >  Python, PyTorch and AWS. </b >
              </i >
              <br />
              <br />
              My field of Interest's are solving challenging problems using &nbsp;
              <i >
                <b className = "purple" >Machine Learning and Artificial Intelligence </b > and
                                                                                            also in areas related
                                                                                            to{" "}
                <b className = "purple" >
                  Finance, Healthcare and Education.
                </b >
              </i >
              <br />
              <br />
              Whenever possible, I also apply my passion for developing solutions
              with
              <i >
                <b className = "purple" > Python, </b >
              </i >
              <i >
              <b className = "purple" >
                  {" "}
                  PyTorch
                </b >
              </i >
              &nbsp; and other
              <b className = "purple" > Web Frameworks </b >
              like
              <i >
                <b className = "purple" > Flask</b >
              </i >
            </p >
          </Col >
          <Col md = {4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Kaus1kC0des"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/KausikDevanath1"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/kausik-devanathan/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/kausik_02"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;